import Mumbai from '../images/popularCities/Mumbai.png'
import Lucknow from '../images/popularCities/Lucknow.png'
import Jaipur from '../images/popularCities/jaipur.png'
import Delhi from '../images/popularCities/Delhi.png'
import Hyderabad from '../images/popularCities/Hyderabad.png'
import chennai from '../images/popularCities/chennai.png'
import Patna from '../images/popularCities/Patna.png'
import Kolkata from '../images/popularCities/Kolkata.png'
import Pune from '../images/popularCities/Pune.png'
import Bangalore from '../images/popularCities/Bangalore.png'
const image = [{
    name: "Mumbai",
    img: Mumbai,
    store:30


},
{
    name: "Delhi",
    img:Delhi
    ,
    store:17


},
{
    name: "Bangalore",
    img: Bangalore
    ,
    store:22


}, {
    name: "Hyderabad",
    img:Hyderabad 
    ,
store:14

}, {
    name: "Lucknow",
    img:Lucknow
    ,
store:7

}, {
    name: "Chennai",
    img:chennai
    ,
    store:20


}, {
    name: "Pune",
    img:Pune
    ,
    store:13


}, {
    name: "Kolkata",
    img: Kolkata,
    store:15


}, {
    name: "Patna",
   img: Patna,
   store:7


}, {
    name: "Jaipur",
   img: Jaipur
    ,
    store:3


}]
export default image